export const indexToMonth = (index) => {
    switch (index) {
        case 1:
            return "Februar"
        case 2:
            return "Mart"
        case 3:
            return "April"
        case 4:
            return "Maj"
        case 5:
            return "Jun"
        case 6:
            return "Jul"
        case 7:
            return "Avgust"
        case 8:
            return "Septembar"
        case 9:
            return "Oktobar"
        case 10:
            return "Novembar"
        case 11:
            return "Decembar"
        default:
            return "Januar"
    }
}

export const waitSeconds = (timeInMs) => {
    return new Promise(resolve => {
        setTimeout(() => {
            return resolve()
        }, timeInMs)
    })
}

export const customSupplierFilter = (options, state) => {
    return options.filter(item =>
        (item.TIN && item.TIN.includes(state.inputValue)) ||
        (item.name && item.name.includes(state.inputValue)) ||
        (item.CIN && item.CIN.includes(state.inputValue))
    )
}

export function getAllCurrentTaxRates(rates) {
    let allRates = []
    for (let i = 0; i < rates.length; i++) {
        for (let j = 0; j < rates[i].taxRates.length; j++) {
            allRates.push(rates[i].taxRates[j])
        }
    }
    return allRates
}


export function blobToImage(image) {
    if (image) {
        return URL.createObjectURL(image);
    }
    return undefined;
}

export const formatQuantity = (qty) => {
    if (qty === undefined || qty === null) {
        return 0;
    }
    let qtyFormat = qty;
    if (typeof qty !== "number") {
        parseFloat((qtyFormat));
    }
    let checkDecimals = qty.toString();
    if (checkDecimals.includes(".") && checkDecimals.split(".")[1].length > 3) {
        return parseFloat(parseFloat(qtyFormat).toFixed(3));
    } else {
        return parseFloat((qtyFormat));
    }
};

export const isImage16by9 = async (file) =>
    await new Promise((resolve) => {
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    const aspectRatio = img.width / img.height;
                    if (Math.abs(aspectRatio - (16 / 9)) < 0.01) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                };
                img.src = e.target.result;
            };
            reader.readAsDataURL(file);
        } else {
            resolve(false);
        }
    });

export const getFileExtension = (filename) => {
    return filename.split(".").pop();
};
