import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AUTH, storageRef } from "../../auth/FirebaseContext";
import { getAllImagesFromFolder } from "../../helper/storage";
import { deleteObject, ref, uploadBytes } from "firebase/storage";
import { getFileExtension } from "../../utils/other";

export const fetchAllAds = createAsyncThunk("images/fetchAllAds", async () => {
  return await getAllImagesFromFolder(`/public/advertisements/${AUTH.currentUser.uid}`);
});

export const saveAds = createAsyncThunk("images/saveAds", async ({ images, startFrom }) => {
  let i = Number(startFrom);
  for (const image of images) {
    if (image instanceof File) {
      const sRef = ref(storageRef,
        `/public/advertisements/${AUTH.currentUser.uid}/${i}.${getFileExtension(image.name)}`);
      await uploadBytes(sRef, image);
      i += 1;
    }
  }
});

export const deleteAds = createAsyncThunk("images/deleteAds", async ({ imageName, url }) => {
  const sRef = ref(storageRef, `/public/advertisements/${AUTH.currentUser.uid}/${imageName}`);
  await deleteObject(sRef);
});

const initialState = {
  ads: [],
  loading: true
};

export const slice = createSlice({
  name: "images",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAds.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllAds.fulfilled, (state, { payload }) => {
        state.ads = payload;
        state.loading = false;
      })
      .addCase(fetchAllAds.rejected, (state, _) => {
        state.loading = false;
      })
      .addCase(saveAds.fulfilled, (state, { meta }) => {
        state.ads = meta.arg.images;
      })
      .addCase(deleteAds.fulfilled, (state, { meta }) => {
        const urlToDelete = meta.arg.url;
        state.ads = state.ads.filter((ad) => ad !== urlToDelete);
      });
  }
});

export const imagesReducer = slice.reducer;
