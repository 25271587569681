import { storageRef } from "../../auth/FirebaseContext";
import { getDownloadURL, listAll, ref } from "firebase/storage";

export const getAllImagesFromFolder = async (folderPath) => {
  try {
    const folderRef = ref(storageRef, folderPath);
    const { items } = await listAll(folderRef);
    return await Promise.all(items.map(async (item) => {
      return await getDownloadURL(item);
    }));
  } catch (error) {
    console.error("Error getting images from folder:", error);
    return null;
  }
};
